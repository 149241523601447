import request from "../request";

export function add(data) {
  return request({
    url: "/model-analysis/riskMaterial/add",
    method: "post",
    data,
  });
}
export function remove(data) {
  return request({
    url: "/model-analysis/riskMaterial/delete",
    method: "post",
    data,
  });
}
export function edit(data) {
  return request({
    url: "/model-analysis/riskMaterial/update",
    method: "post",
    data,
  });
}
export function fetchList(data) {
  return request({
    url: "/model-analysis/riskMaterial/queryWIthPage",
    method: "post",
    data,
  });
}
